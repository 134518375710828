import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from, until } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled, { css } from 'styled-components';
const Icon = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transition: opacity 175ms ease, transform 175ms ease;
  opacity: 0;
  transform: translateX(-10px);
  margin-top: auto;

  @media ${until(Device.Tablet)} {
    opacity: 1;
  }

  > svg {
    display: block;
    width: 18px;
    height: 21px;
    stroke: ${brand.black};
    stroke-width: 2px;
    transform: rotate(90deg);

    [data-featured='true'] & {
      stroke: ${brand.white};
    }
  }
`;
const Card = styled.a `
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  color: ${brand.black};
  text-decoration: none;
  position: relative;

  ${(props) => props?.isLatestNewsWidget &&
    css `
      height: auto !important;
    `}

  &:hover ${Icon} {
    transform: translateX(0px);
    opacity: 1;
  }

  &:hover,
  &:active,
  &:focus {
    color: ${brand.black};
    text-decoration: none;
  }

  [data-featured='true'] & {
    color: ${brand.white};
    background-color: ${brand.grey.grey20};
  }
`;
const TagArea = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
`;
const NoticeTag = styled.span `
  padding: 8px 17px;
  margin-right: 10px;
  ${ThemeSwitch({ groundforceColour: brand.primary.alt })};
  background-color: var(--theme);
  ${fonts.DaxPro.Bold};
  font-size: 20px;
  color: ${brand.white};
  z-index: 1;
`;
const Media = styled.div `
  position: relative;
  aspect-ratio: 1;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  ${(props) => props?.isLatestNewsWidget &&
    css `
      aspect-ratio: 16/9;
      img {
        object-fit: cover !important;
        width: 100% !important;
      }

      @media ${until(Device.MobileLarge)} {
        aspect-ratio: auto;
        display: block;
      }
    `}
`;
const Content = styled.div `
  flex: 1 0 auto;
  background-color: ${brand.white};
  padding: 18px 16px;
  display: flex;
  flex-direction: column;

  @media ${from(Device.Tablet)} {
    padding: 23px 29px;
  }

  ${ParagraphStyles.Paragraph} {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }

  [data-featured='true'] & {
    background-color: ${brand.grey.grey20};

    @media ${from(Device.Tablet)} {
      min-height: 285px;
    }
  }
`;
const Date = styled.span `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  color: ${brand.grey.grey20};
  margin-bottom: 12px;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
    line-height: 28px;
  }

  [data-featured='true'] & {
    color: ${brand.grey.grey89};
  }
`;
const Tag = styled.span `
  ${fonts.DaxPro.Regular};
  font-size: 16px;
  color: ${brand.grey['grey55']};
  margin-bottom: 13px;

  @media ${from(Device.Tablet)} {
    font-size: 18px;
  }

  [data-featured='true'] & {
    color: ${brand.grey.grey89};
  }
`;
const DivisionTag = styled.span `
  --tagColor: ${brand.divisionTags.default};
  padding: 8px 17px;
  background-color: var(--tagColor);
  ${fonts.DaxPro.Bold};
  font-size: 20px;
  color: ${brand.white};
  z-index: 1;

  &[data-tag-colour='division-sandhurst'] {
    --tagColor: ${brand.divisionTags.sandhurst};
  }

  &[data-tag-colour='division-mrcropper'] {
    --tagColor: ${brand.divisionTags.mrCropper};
  }
`;
const Title = styled.h3 `
  margin: 0px;
  color: ${brand.grey.grey20};
  font-size: 16px;
  margin-bottom: 8px;
  ${fonts.DaxPro.Medium};

  ${(props) => props?.isLatestNewsWidget &&
    css `
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    `}

  @media ${from(Device.Tablet)} {
    font-size: 22px;
    line-height: 28px;
  }

  [data-featured='true'] & {
    color: ${brand.white};

    @media ${from(Device.Tablet)} {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;
const ParagraphContent = styled(Paragraph) `
  white-space-collapse: preserve;
  size: 16px;
  line-height: 26px;
  color: ${brand.grey.grey20};

  [data-featured='true'] & {
    color: ${brand.white};
    @media ${from(Device.Tablet)} {
      font-size: 18px;
    }
  }
`;
export const DocumentCardStyles = {
    Card,
    Date,
    Media,
    NoticeTag,
    Content,
    ParagraphContent,
    Tag,
    TagArea,
    DivisionTag,
    Title,
    Icon,
};
